import {Helmet} from "react-helmet";

function Head() {
    return (
        <div className="application">
            <Helmet>
                <script src="https://unpkg.com/react/umd/react.production.min.js" crossorigin></script>
	    	        <script src="https://unpkg.com/react-dom/umd/react-dom.production.min.js" crossorigin></script>
	    	        <script src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js" crossorigin></script>
                <title>hackSend</title>
            </Helmet>
        </div>
    );
}

export default Head;
